import React from 'react';
import { Modal } from 'semantic-ui-react';

function DisplayPlayersModal({players, open, setOpen}) {
    return (
        <Modal
            onClose={()=>setOpen(false)}
            open={open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
        >
            <Modal.Header>Liste des contacts des joueurs</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <table style={{borderCollapse: 'separate', borderSpacing: '10px'}}>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Téléphone</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {players.map((player) => (
                                <tr>
                                    <td>{player.surname}</td>
                                    <td>{player.tel}</td>
                                    <td>{player.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
}

export default DisplayPlayersModal;
